.input_style {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
}

input:focus {
  border: 1px solid #047267;
}

.form-border {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.contact_label {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #90c347;
}

.button {
  border-radius: 4px;
}
