@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google poppins font import */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  color: #222;
  background-color: #d3e7b5;
}

/******all medicine table******/

table th {
  border-radius: 10px;
}

/*******about page*******/
.transition_move {
  transition: all 0.3s;
}

.bottom_border {
  border-bottom: 1px solid #ddd;
}

/******jodit editor toolbar bg ******/

.jodit-toolbar__box:not(:empty):not(:empty),
.jodit-status-bar {
  background-color: #f0fdf4 !important;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
