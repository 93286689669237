.water-slider .range-slider {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* .water-slider .range-slider input[type="range"] {
   
    width: 100%;
    background: transparent;
} */


.water-slider .range-slider input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
.water-slider .range-slider__range {

    width: 100%;
    height: 6px;
    border-radius: 5px;
    background: #d7dcdf;
    outline: none;
    padding: 0;
    margin: 24px 0px;
    z-index: 100;
}
.water-slider .range-slider__range::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #2c3e50;
    cursor: pointer;
    transition: 0.15s ease-in-out;
}
.water-slider .range-slider__range::-webkit-slider-thumb:hover {
    background: #0069b2;
}
.water-slider .range-slider__range:active::-webkit-slider-thumb {
    background: #0069b2;
}
/* .water-slider .range-slider__range::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #2c3e50;
    cursor: pointer;
    transition: 0.15s ease-in-out;
} */
/* .water-slider .range-slider__range::-moz-range-thumb:hover {
    background: #0069b2;
} */
/* .water-slider .range-slider__range:active::-moz-range-thumb {
    background: #0069b2;
} */
.water-slider .range-slider__range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px #0069b2;
}
.water-slider .range-slider__value {
    width: 60px;
    /* color: #fff; */
    text-align: center;
    border-radius: 4px;
    /* background: #0A2004; */
    padding: 8px 12px;
}
/* .water-slider ::-moz-range-track {
    background: #d7dcdf;
    border: 0;
} */
/* .water-slider input::-moz-focus-inner, .water-slider input::-moz-focus-outer {
    border: 0;
} */
